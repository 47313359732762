<template>
  <ol class="flix-flex" :key="$store.state.service.updateKey">
    <li v-for="(service, index) in getServices()" :key="index">
      <transition name="flixFadeIn" mode="out-in">
        <editService
          v-if="$store.state.service.editService === index"
          :index="index"
        />
        <previewService v-else :index="index" />
      </transition>
    </li>
  </ol>
</template>
<script>
export default {
  components: {
    editService() {
      return import("./editService");
    },
    previewService() {
      return import("./previewService");
    }
  },
  props: {},
  data() {
    return {
      services: JSON.parse(JSON.stringify(this.$store.state.service.unsaved))
    };
  },
  computed: {},
  mounted() {},
  methods: {
    getEditService() {
      return this.$store.state.service.editService;
    },
    deleteImage(index) {
      this.saveImage(index, "");
    },
    getUpload(index) {
      var services = JSON.parse(
        JSON.stringify(this.$store.state.service.unsaved)
      );
      var set = {
        onSave: function(ret) {
          this.saveImage(index, ret);
        }.bind(this),
        label: '<span class="flix-btn">Bild hochladen</span>',
        maxWidth: 350,
        maxHeight: 350
      };
      if (services[index].img) {
        set.image = { dataUrl: services[index].img };
      }
      return set;
    },
    saveImage(index, data) {
      this.services = JSON.parse(
        JSON.stringify(this.$store.state.service.unsaved)
      );

      if (!data) {
        this.services[index].img = "";
        this.setSave();
        return false;
      }

      var send = {
        user: false,
        data: {
          img: data.dataUrl,
          type: data.info.type,
          name: data.info.name
        }
      };
      this.$flix_post({
        data: send,
        url: "uploads/anonym",
        callback: function(ret) {
          this.services[index].img = ret.data[1].url;
          this.setSave();
        }.bind(this)
      });
    },
    getServices() {
      return JSON.parse(JSON.stringify(this.$store.state.service.unsaved));
    },
    removeService(index) {
      this.services = JSON.parse(
        JSON.stringify(this.$store.state.service.unsaved)
      );
      this.services.splice(index, 1);
      this.setSave();
      this.$store.commit("service/setUpdateKey");
    },
    setPrice(value, index) {
      this.services = JSON.parse(
        JSON.stringify(this.$store.state.service.unsaved)
      );
      this.services[index].price = value;
      this.setSave();
    },
    setDescription(value, index) {
      this.services = JSON.parse(
        JSON.stringify(this.$store.state.service.unsaved)
      );
      this.services[index].description = value;
      this.setSave();
    },
    setTitle(value, index) {
      this.services = JSON.parse(
        JSON.stringify(this.$store.state.service.unsaved)
      );
      this.services[index].title = value;
      this.setSave();
    },
    setSave() {
      this.$store.commit("service/prefetch", this.services);
    }
  }
};
</script>
<style lang="sass" scoped>
@import '@/assets/sass/variables.sass'
ol
  margin: 0
  padding: 0
  display: flex
  align-items: center
  justify-content: flex-start
  flex-wrap: wrap
  margin-bottom: 20px
  margin-left: -10px
  margin-right: -10px
  margin-top: -10px
  li
    list-style: none
    width: 100%
    position: relative
    box-sizing: border-box
    flex-direction: row
    position: relative
    box-sizing: border-box
    margin: 10px
    padding: 20px
    border-radius: 5px
    padding: 20px 15px
    border: 1px solid $grey
    a.flix-btn-end
      text-decoration: none
      margin-left: 5px
      justify-content: flex-start
      gap: 5px
      color: $dark-grey
      .icon
        color: $middle-grey
        font-size: 20pt
        &.active
          color: $success
    a.flix-btn-remove
      margin-right: 10px
      position: absolute
      right: 0
      top: 10px
      font-size: 18pt
      color: $dark-grey
    .down
      display: none
    .right
      display: flex


@media(max-width: 500px)
  ol
    justify-content: center
    li
      flex-direction: column
      a.flix-btn-end
        margin: 10px 0 0 0
      a.flix-btn-remove
        margin: 0 0 10px 0
        text-align: right
        display: flex

      .down
        display: flex
      .right
        display: none
</style>
